import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Thanks from './components/Thanks';
import PageNotFound from './components/PageNotFout';
import { Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Header />
      <div className="App-page">
        <Routes>
          <Route path="/" index element={<Home />} />
          <Route path="thanks" element={<Thanks />} />
          <Route path="/*" index element={<PageNotFound />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
