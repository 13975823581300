function Thanks() {
    return (
        <div className="home centered">
            <div className="thanks-container">
                <div className="thanks">
                    Bedankt, de betaling is gelukt!
                </div>
            </div>
        </div>
    );
}

export default Thanks;