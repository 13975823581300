
function Footer() {
    return (
        <footer className="App-footer">
            <div>
                Merijn Vogelsang - Miltenburgstraat 48, 3552XG Utrecht<br></br>
                KVK 86317202 - BTW NL004227200B31 

            </div>
        </footer>

    );
}

export default Footer;