import { Link } from "react-router-dom";
import logo from "../mv_logo.svg";

function Header() {


    return (
        <header className="App-header">
            <Link to={"/"}>
                <div></div>
                <img src={logo} className="mv-logo" alt="logo" />
                <p className="title">
                    Merijn Vogelsang
                </p>
            </Link>

        </header>
    );
}

export default Header;