import React, { useState } from "react";
import Contact from "./Contact";

function Home() {

    const [ideaHover, setIdeaHover] = useState(false);

    return (
        <div className="home">
            <div className="tagline">
                <h2>Ik maak dingen,</h2>
                <a
                    onMouseEnter={() => setIdeaHover(true)}
                    onMouseLeave={() => setIdeaHover(false)}
                >
                    breng {ideaHover && 'jouw?'} ideeën tot leven.
                </a>
                <br></br>
                <br></br>
                Games // websites // apps
                <br></br>
                <br></br>
                Ik transformeer ideeën naar concepten,<br></br>
                concepten naar snelle prototypes,<br></br>
                en prototypes naar een bruikbaar product.
            </div>
            <Contact />
        </div>
    );
}

export default Home;