import { Link } from 'react-router-dom'

function PageNotFound() {
    return (
        <div className="page-not-found appear">
            It's not here...<br></br>
            What were you looking for?
            <br></br><br></br>
            Check the url or go <Link to="/">home</Link>.
        </div>
    );
}

export default PageNotFound;