function Contact() {
    return (
        <div>
            <h2>Contact</h2>
            06 11041602<br></br>
            <a href="mailto:info@merijnvogelsang.com">info@merijnvogelsang.com</a>
        </div>
    );
}

export default Contact;